






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'

interface ItemsStepper {
  label: string;
  position?: string;
  date?: string;
}

@Component({
  inheritAttrs: false,
  components: {
    CaretDownIcon
  },
})
export default class Stepper extends Vue {
  @Prop({ type: Array, default: [] }) private items!: ItemsStepper[]
  @Prop({ type: Number, default: 0 }) private prefixData!: number
  @Prop({ type: String, default: 'horizontal' }) private variant!: 'horizontal' | 'vertical'
  @Prop({ type: Boolean, default: true }) private showAll!: boolean

  showAllData = this.showAll

  get dataItems(): ItemsStepper[] {
    return this.items
  }

  getColorIcon(state: string): string {
    return state === "past" ? '#B82025' : '#CCCCCC';
  }

  private onToggleShow() {
    this.showAllData = !this.showAllData
  }
}
